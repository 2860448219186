// OrderDeleteMessage.js
import React from 'react';
import { useTranslation } from 'react-i18next';

const FlawDeleteMessage = ({ item }) => {
    const { t } = useTranslation('navbar');

    const message = t('flawDeleteMsg', { user: item.user.username, partyNo: item.party_no, rollCount: item.roll_count});

    return (
        <span dangerouslySetInnerHTML={{ __html: message }} />
    );
};

const OrderDeleteMessage = ({ item }) => {
    const { t } = useTranslation('navbar');

    const message = t('deleteMsg', { user: item.user.username, partyNo: item.party_no });

    return (
        <span dangerouslySetInnerHTML={{ __html: message }} />
    );
};


const OtherNotificationMessage = ({item}) => {
    const { t } = useTranslation('navbar');

    const message = t(
        'otherDeleteMsg',
        { user: item.username, partyNo: item.party_no, rollCount: item.roll_count }
    );

    return (
        <span dangerouslySetInnerHTML={{ __html: message }} />
    );

}
export { OrderDeleteMessage, OtherNotificationMessage, FlawDeleteMessage };
