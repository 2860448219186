import flagus from "../../assets/images/flags/us.svg";
import flagtr from "../../assets/images/flags/tr.svg";

const languages = {
    tr: {
      label: "Türkçe",
      flag: flagtr,
    },
    en: {
      label: "English",
      flag: flagus,
    }
  }

export default languages