import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
}
    from 'reactstrap';
import languages from './languages'


const LanguageDropdown = (props) => {
    const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
    const [selectedLang, setSelectedLang] = useState("tr");
    const toggleLanguageDropdown = () => { setIsLanguageDropdown(!isLanguageDropdown) };

    const { i18n } = useTranslation();

    useEffect(() => {
        const currentLanguage = i18n.language || "tr";
        setSelectedLang(currentLanguage);
    }, [i18n.language]);


    const changeLanguageAction = (lng) => {
        i18n.changeLanguage(lng);
        setSelectedLang(lng);
    };


    return (
        <React.Fragment>
            <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
                <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
                    <img
                        src={languages[selectedLang]?.flag || languages["tr"].flag}
                        alt={languages[selectedLang]?.label || languages["tr"].label}
                        height="20"
                        className="rounded"
                    />
                </DropdownToggle>
                <DropdownMenu className="notify-item language py-2">
                    {Object.keys(languages).map(key => (
                        <DropdownItem
                            key={key}
                            onClick={() => changeLanguageAction(key)}
                            className={`notify-item ${selectedLang === key ? "active" : "none"
                                }`}
                        >
                            <img
                                src={languages[key]?.flag}
                                alt={languages[key]?.label}
                                className="me-2 rounded"
                                height="18"
                            />
                            <span className="align-middle">
                                {languages[key]?.label}
                            </span>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )
}

export { LanguageDropdown };