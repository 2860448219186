import React, { useEffect, useMemo, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Label,
    Modal, ModalBody, ModalHeader,
    Row,
} from 'reactstrap';
import { PartyFilter, FlawTypeFilter } from './PartySearch';
import { ReportTypeFilter } from './ReportType';
import DatePickerMaterialUI from './DatePicker';
import { getFilteredFlawWithPartyNo, getFlawDetails, FlawTypeDropdown } from "../../api/flaw/Flaw";
import ReportTable from "./ReportTable";
import { convertDate } from "../../Components/Common/helper";
import { ExcelReport, SummaryExcelReport, QualityExcelReport } from "../../api/report/Report";
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";
import { Triangle } from "react-loader-spinner";
import LineChart from '../../Layout/charts/LineChart';
import { getFlawTypeCountRange } from '../../api/flawtype/FlawType';

const ReportPanel = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [partyNo, setPartyNo] = useState(null);
    const [flawType, setFlawType] = useState(null);
    const [flawTypeInfo, setFlawTypeInfo] = useState(null);
    const [flawDetail, setFlawDetail] = useState(null);
    const [modal, setModal] = useState(false);

    const [reportData, setReportData] = useState(null);
    const [clearTable, setClearTable] = useState(false);
    const [reportType, setReportType] = useState(null);
    const [flawRange, setFlawRange] = useState(null);

    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [loader, setLoader] = useState(false);

    const { t } = useTranslation(['reportPanel']); // Using the 'reportPanel' namespace

    const loaderToogle = () => { setLoader(!loader) };
    const forceRemoveLoader = () => { setLoader(false) };

    useEffect(() => {
        // get flaw type dropdown
        FlawTypeDropdown().then((response) => {
            setFlawTypeInfo(response.result);
        });
    }, []);

    useEffect(() => {
        if (flawTypeInfo) {
            const typeCode = sessionStorage.getItem('flaw_type_code')
            if (typeCode) {
                const params = { flaw_type_code: typeCode, limit: 7 };
                fetchFlaw(params)
            }
        }
    }, [flawTypeInfo]);

    useEffect(() => {
        setStartDate(null)
        setEndDate(null)
        setPartyNo(null)
        setFlawType(null)
        setFlawRange(null)
    }, [reportType]);

    useEffect(() => {
        setFlawRange(null)
    }, [startDate, endDate]);


    const getFlawType = (flaw_type_id) => {
        // get flaw type name from flaw type id
        let flawType;
        flawTypeInfo.map((flaw) => {
            if (flaw[0] === flaw_type_id) {
                flawType = flaw[1];
            }
        });
        return flawType;
    };

    const fetchFlaw = async (params, url) => {
        // fetch filtered flaw from backend
        let response;
        if (url) {
            response = await getFilteredFlawWithPartyNo(undefined, url);
        }
        else {
            response = await getFilteredFlawWithPartyNo(params, undefined);
        }
        if (response.request.status === 200) {
            setReportData(response.data);

        };
    };

    const fetchFlawRange = async (params) => {

        // fetch filtered flaw from backend
        try {
            let response;
            const queryParams = new URLSearchParams(params).toString();
            response = await getFlawTypeCountRange(queryParams);
            setFlawRange(response);
        } catch (error) {
            console.error('HandleSubmit Error:', error);
        }
    };

    const getParams = () => {
        const params = {}
        params['order'] = partyNo && (partyNo.map((item) => parseInt(item.value))).join(',')
        params['flaw_type_code'] = flawType && (flawType.map((item) => parseInt(item.value))).join(',')
        params['created_at_after'] = startDate && startDate;
        params['created_at_before'] = endDate && endDate;
        params['limit'] = 7;
        return params;
    }

    const getParamsRange = () => {
        const params = {};
        params['flaw_type_code'] = flawType && flawType.map((item) => parseInt(item.value)).join(',');
        params['start_date'] = startDate && startDate.split('T')[0]; // Convert date format
        params['end_date'] = endDate && endDate.split('T')[0];       // Convert date format
        return params;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // get filter parameters
        const params = getParams();
        const paramsRange = getParamsRange();
        setClearTable(true);
        setTimeout(() => {
            setClearTable(false);
        }, 1000)
        if (reportType?.value === 2) {
            if (flawType && startDate && endDate) {
                setLoader(true);
                try {
                    await fetchFlawRange(paramsRange);
                } catch (error) {
                    console.error("Error fetching flaws:", error);
                } finally {
                    setLoader(false);
                }
            } else {
                setErrorMsg(t('forGraphicalReportMsg'));
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000)
            }
        } else {
            await fetchFlaw(params, undefined);
        }
        sessionStorage.removeItem('flaw_type_code')  // remove flaw type code from session storage
    };

    const changePage = async (e, rotation) => {
        e.preventDefault();
        // change page according to income rotate (next, previous)
        const url = reportData[rotation]

        // fetch flaws
        await fetchFlaw(undefined, url);
    }

    const toggle = (flaw_id) => {
        // flaw detail modal toggle
        // when we close the modal, it returns event object
        // this cause to error in getFlawDetails function
        // so we check the type of flaw_id
        if (typeof (flaw_id) === 'number') {
            getFlawDetails(flaw_id).then((response) => {
                setFlawDetail(response.data);
                setModal(!modal);
            });
        }
        else {
            setModal(!modal);
        };
    };

    const getPartyNo = (orderId) => {
        // get party no by order id from state
        if (!partyNo) return orderId;
        const data = partyNo.filter((item) => (
            parseInt(item.value) === parseInt(orderId)
        ))
        return data.length > 0 ? data[0].label : orderId;
    };


    const searchTable = reportData && reportData.results.map((item) => {
        //console.log(item.image)
        const flaws = {
            "id": item.id,
            "image": item.image,
            "flaw_type": getFlawType(item.flaw_type_code),
            // "roll": item.roll,
            "order": item.party_no,
            "flaw_location": (item.millimeter) / 1000,
            "flaw_width": item.flaw_size.width,
            "flaw_height": item.flaw_size.height,
            "created_at": convertDate(item.created_at)
        }
        return flaws;
    });

    const columns = useMemo(() => [
        {
            Header: t('flawImage'),
            accessor: 'image',
            Cell: ({ cell: { value }, row: { original } }) => (
                <img
                    src={value}
                    alt="flaw_image"
                    loading='lazy'
                    style={{ maxWidth: '48px', maxHeight: '48px', height: '48px', cursor: 'pointer' }}
                    onClick={() => toggle(original.id)}
                />
            ),
        },
        {
            Header: t('flawType'),
            accessor: 'flaw_type',
            Cell: ({ cell: { value }, row: { original } }) => {
                return (
                    <span>{value}</span>
                )
            }
        },
        { Header: t('partyNo'), accessor: 'order' },
        // { Header: 'Top', accessor: 'roll' },
        { Header: t('flawPlace') + ' (' + t('shortMeter') + ')', accessor: 'flaw_location' },
        { Header: t('flawWidth') + ' (' + t('shortMillimeter') + ')', accessor: 'flaw_width' },
        { Header: t('flawHeight') + ' (' + t('shortMillimeter') + ')', accessor: 'flaw_height' },
        { Header: t('createdAt'), accessor: 'created_at' },
    ], [t]);

    const handleDateSelect = (e, func) => {
        /* when a datetime selected, convert this to ISO string
        params: 
            e -> date data
            func -> state handler function
        */
        const date = new Date(e);
        const ISOformat = date.toISOString();
        func(ISOformat);
    }

    const handleExcelReport = async (e) => {
        e.preventDefault();

        // Assuming getParams() returns an object with keys and values
        const params = getParams();
        // change created_at_after as created_at

        // Remove 'limit' key and filter out null values
        const filteredParams = Object.fromEntries(
            Object.entries(params)
                .filter(([key, value]) => key !== 'limit' && value !== null)
        );

        try {
            loaderToogle();

            const response = await ExcelReport(filteredParams)
            // Create a Blob from the response data
            const blob = new Blob([response.data], {
                type: response.headers['content-type'], // Get the content type from response headers
            });

            // Create a link element
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute('download', 'report.xlsx');

            // Append link to the body
            document.body.appendChild(link);

            // Trigger click event to start download
            link.click();

            // Remove the link element
            document.body.removeChild(link);

            // Release the blob URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        } finally {
            forceRemoveLoader(); // Remove the loader when the download is finished or an error occurs
        }

        // console.log(param)

        // window.location.href=`${process.env.REACT_APP_API_URL}/report/?${param}`;
    }


    const handleSummaryExcelReport = async (e) => {
        e.preventDefault();

        // Assuming getParams() returns an object with keys and values
        const params = getParams();
        // change created_at_after as created_at

        // Remove 'limit' key and filter out null values
        const filteredParams = Object.fromEntries(
            Object.entries(params)
                .filter(([key, value]) => key !== 'limit' && value !== null)
        );

        try {
            loaderToogle();

            const response = await SummaryExcelReport(filteredParams)
            // Create a Blob from the response data
            const blob = new Blob([response.data], {
                type: response.headers['content-type'], // Get the content type from response headers
            });

            // Create a link element
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute('download', 'report.xlsx');

            // Append link to the body
            document.body.appendChild(link);

            // Trigger click event to start download
            link.click();

            // Remove the link element
            document.body.removeChild(link);

            // Release the blob URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        } finally {
            forceRemoveLoader(); // Remove the loader when the download is finished or an error occurs
        }
    }

    const handleQualityExcelReport = async (e) => {
        e.preventDefault();

        // Assuming getParams() returns an object with keys and values
        const params = getParams();
        // change created_at_after as created_at

        // Remove 'limit' key and filter out null values
        const filteredParams = Object.fromEntries(
            Object.entries(params)
                .filter(([key, value]) => key !== 'limit' && value !== null)
        );

        try {
            loaderToogle();

            const response = await QualityExcelReport(filteredParams)
            // Create a Blob from the response data
            const blob = new Blob([response.data], {
                type: response.headers['content-type'], // Get the content type from response headers
            });

            // Create a link element
            const link = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            link.href = url;

            // Set the download attribute with a filename
            link.setAttribute('download', 'order_quality_report.xlsx');

            // Append link to the body
            document.body.appendChild(link);

            // Trigger click event to start download
            link.click();

            // Remove the link element
            document.body.removeChild(link);

            // Release the blob URL
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading the Excel file:', error);
        } finally {
            forceRemoveLoader(); // Remove the loader when the download is finished or an error occurs
        }
    }

    const handleReport = async (e) => {
        e.preventDefault();
        if (reportType && reportType !== null) {
            // Control 'Summary Report', 'General Report' and 'Quality Report'
            const isQualitySelected = reportType.value === 3;
            const isSummarySelected = reportType.value === 1;
            const isGeneralSelected = reportType.value === 0;

            if (isQualitySelected || ((Array.isArray(partyNo) && partyNo.length > 0) || (startDate && endDate))) {
                // Control 'Summary Report' and 'General Report'
                
                if (isSummarySelected) {
                    await handleSummaryExcelReport(e); // 
                } else if (isGeneralSelected) {
                    await handleExcelReport(e);
                } else if (isQualitySelected) {
                    await handleQualityExcelReport(e);
                }

            } else {
                setErrorMsg(t('forExcelReportMsg'));
                setError(true);
                setTimeout(() => {
                    setError(false);
                }, 2000)

            }
        } else {
            setErrorMsg(t('reportChooseMsg'));
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 2000)
        }
    };

    return (
        <div className="page-content ">
            <Container fluid>
                {/* LOADER */}
                <div className="loader"
                    style={{
                        background: '#a5a5a5cf', position: 'absolute',
                        top: '0', right: '0', left: '0', bottom: '0',
                        zIndex: '1000000', display: loader ? 'block' : 'none',
                        overflow: 'hidden'
                    }}>
                    <div className="loader-animation" style={{ position: 'relative', top: '50%', left: '50%', color: '#00425a' }}>
                        <Triangle
                            height={64}
                            width={64}
                            color={'#00425a'}
                            ariaLabel="triangle-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                        />
                    </div>
                    <p style={{ position: 'relative', top: '50%', left: '50%', translate: '-20px', transform: 'translateY(10px)', color: '#00425a' }}>
                        {(reportType?.value === 2) ? t('graphicalComparisonReportPreparing') : t('excelReportDownloading')}
                    </p>
                </div>

                {/* ALERTS */}
                {error && (
                    <div className="alert alert-danger alert-dismissible fade show text-center" role="alert">
                        <strong >{errorMsg && errorMsg}</strong>
                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={() => setError(false)}></button>
                    </div>
                )}

                {/* MODALS */}
                <Modal isOpen={modal}
                    toggle={toggle}
                    centered={true}
                    size={"xl"}>
                    <ModalHeader toggle={toggle} className="px-5 ms-3 text-uppercase">
                        <h4>{t('flawDetails')}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-md-6" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                                {flawDetail && flawDetail.flaw_coordinate &&
                                    <div >
                                        <img id={`flaw_image_${flawDetail.id}`}
                                            src={modal && `${flawDetail.image}`} alt="flaw_image"
                                            loading="lazy"
                                            style={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                borderRadius: '3px'
                                            }}
                                        />
                                    </div>
                                }

                            </div>
                            <div className="col-md-6 text-center mt-4 fs-5">
                                <div className="row pt-3">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{t('flawType')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold fs-5'>{flawDetail && getFlawType(flawDetail.flaw_type_code)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{t('flawWidth')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail.flaw_size.width} <span className='text-muted'>{t('millimeter')}</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{t('flawHeight')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail.flaw_size.height} <span className='text-muted'>{t('millimeter')}</span></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 text-start">
                                        <p className='fs-5'><b>{t('flawPlace')}:</b></p>
                                    </div>
                                    <div className="col-md-6 text-start">
                                        <p className='fw-bold'>{modal && flawDetail.millimeter / 1000} <span className='text-muted'>{t('meter')}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                <Row>
                    <Col lg="3">
                        <Card>
                            <CardHeader className='pb-0'>
                                <h4 className="card-title mb-0">{t('filters')}</h4>
                            </CardHeader>
                            <hr></hr>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    {/* REPORT TYPE FILTER SELECT */}
                                    <div className=''>
                                        <Label htmlFor="report_filter">{t('reportType')}</Label>
                                        <ReportTypeFilter
                                            id="report_filter"
                                            setReportType={setReportType} t={t}
                                        // defaultValue={paramsData && paramsData}
                                        />
                                    </div>
                                    {/* PARTY FILTER SELECT */}
                                    {(reportType?.value === 2 || reportType?.value === 3) ?
                                        <div />
                                        :
                                        <div className='mt-4'>
                                            <Label htmlFor="party_filter">{t('partyNo')}</Label>
                                            <PartyFilter id="part_filter" setPartyNo={setPartyNo} t={t} key={reportType?.value}
                                            />
                                        </div>
                                    }
                                    {/* FLAW TYPE FILTER SELECT */}
                                    {(reportType?.value === 3) ?
                                        <div />
                                        :
                                        <div className='mt-4'>
                                            <Label htmlFor="flaw_filter">{t('flawType')}</Label>
                                            <FlawTypeFilter
                                                id="flaw_filter"
                                                flawType={flawType}
                                                setFlawType={setFlawType} t={t}
                                                isMulti={(reportType?.value === 2) ? false : true}
                                                key={reportType?.value}
                                            // defaultValue={paramsData && paramsData}
                                            />
                                        </div>
                                    }

                                    {/* TIME RANGE FILTER */}
                                    <div className='d-flex justify-content-between align-items-end'>
                                        <Label className='mt-4'>{t('dateRange')}</Label>
                                        <Button className='btn-sm' color='primary'
                                            onClick={() => {
                                                setStartDate(null);  // clear start date
                                                setEndDate(null);    // clear end date
                                            }}
                                        >{t('clearDate')}</Button>
                                    </div>
                                    <Row className='mt-1'>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <DatePickerMaterialUI
                                                label={t('startDate')}
                                                handleSelect={handleDateSelect}
                                                func={setStartDate}
                                                type="start"
                                                date={startDate}
                                                key={reportType?.value}
                                            />
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <DatePickerMaterialUI
                                                label={t('endDate')}
                                                handleSelect={handleDateSelect}
                                                func={setEndDate}
                                                type="end"
                                                date={endDate}
                                                startDate={startDate}
                                                key={reportType?.value}
                                            />
                                        </Col>
                                    </Row>

                                    <Row className='mt-4'>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <Button className='w-100' color='primary' type='submit'>{t('preview')}</Button>
                                        </Col>
                                        {(reportType?.value === 2) ?
                                            <div />
                                            :
                                            <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <Button className='w-100' color='success' type='button'
                                                    onClick={handleReport}>{t('excelReport')}</Button>
                                            </Col>
                                        }
                                    </Row>
                                </Form>



                            </CardBody>
                        </Card>
                    </Col>

                    <Col lg="9">
                        <Card>
                            <CardHeader className='pb-0'>
                                <h4 className="card-title mb-0">{t('reports')}</h4>
                            </CardHeader>
                            <hr></hr>
                            <CardBody className="mt-0 pt-0">
                                {
                                    reportType?.value === 2 &&
                                        flawType?.length === 1 &&
                                        startDate !== null &&
                                        endDate !== null && flawRange
                                        ? (
                                            <div className="mt-4">
                                                <LineChart flaws={flawRange} startDate={startDate} endDate={endDate} />
                                            </div>
                                        ) : (
                                            searchTable ? (
                                                <ReportTable
                                                    searchTable={searchTable}
                                                    columns={columns}
                                                    reportData={reportData}
                                                    pageSize={7}
                                                    changePage={changePage}
                                                    clearTable={clearTable}
                                                    t={t}
                                                />
                                            ) : (
                                                <div className="text-muted text-center" style={{
                                                    width: '100%',
                                                    paddingTop: '0',
                                                    fontSize: '24px',
                                                    fontWeight: 'bolder',
                                                }}>
                                                    {t('previewWarning')}
                                                </div>
                                            )
                                        )
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};


export default ReportPanel;