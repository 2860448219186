import React, { useEffect, useState } from 'react';
import { GetPendingOrders, removeOrder, RejectParty } from '../../api/order/Order';
import { restoreFlaw, markAsReadToFlaw, GetPendingFlaws, removeFlaw, RejectDeleteFlaw } from '../../api/flaw/Flaw.js';
import { convertDate } from "../Common/helper.js";
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalHeader,
    Col,
    Row,
    UncontrolledAlert
}
    from 'reactstrap';
import { isEmptyArray } from 'formik';
import { OrderDeleteMessage, OtherNotificationMessage, FlawDeleteMessage } from "../../Layout/Message";
import { getMachineService } from "../../api/machine/Machine";
import { stopMachineAPI } from "../../api/machine/Machine";



const Notification = (props) => {

    const [isOpen, setIsOpen] = useState(false);
    const [pendingOrders, setPendingOrders] = useState([]);
    const [pendingFlaws, setPendingFlaws] = useState([]);
    const [countOrder, setCountOrder] = useState(0);
    const [countFlaw, setCountFlaw] = useState(0);
    const [notification, setNotification] = useState([]);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [currentObject, setCurrentObject] = useState(null);
    const [alert, setAlert] = useState(null);
    const [alertMsg, setAlertMsg] = useState(null);

    const toggleDropdown = () => { setIsOpen(!isOpen) };

    const toggleNotification = (e, object) => {
        setCurrentObject(object);  // set current notification to state
        setIsNotificationOpen(!isNotificationOpen);  // switch toggle
    };

    const fetchPendingOrders = async () => {
        const response = await GetPendingOrders();
        if (response.status === 200) {
            setPendingOrders(response.data);
            setCountOrder(response.data.length);
        }
    }

    const fetchPendingFlaws = async () => {
        const response = await GetPendingFlaws();
        if (response.status === 200) {
            setPendingFlaws(response.data);
            setCountFlaw(response.data.length);
        }
    }

    useEffect(() => {
        /**
         * Fetch pending orders and flaws when component mounted
         * and set interval to fetch pending orders and flaws every 10 minutes
         */
        fetchPendingOrders();
        fetchPendingFlaws()
        const interval = setInterval(() => {
            fetchPendingOrders();
            fetchPendingFlaws()
        }
            , 60000);
        return () => clearInterval(interval);
    }, []);

    const fetchMachineService = async () => {
        /**
         * This function fetch machine service data
         * get count, next, prev data
         * and set data to state
        */
        const response = await getMachineService();
        if (response.request.status === 200) {
            return response
        }
    };


    const handleAccept = async (order_id) => {
        /**
         * Delete order from database
        */
        const response = await removeOrder(order_id);
        if (response.status === 204) {
            // if order is deleted, check machines and stop if running,  fetch pending orders again
            const machineResponse = await fetchMachineService();
            const machineData = machineResponse.data.results;
            const matchingMachine = machineData.find(machine =>
                machine.order && machine.order.id === order_id && machine.status === true
            );
            if (matchingMachine) {
                const machineId = matchingMachine.machine.id;
                try {
                    await stopMachineAPI(machineId);
                } catch (error) {
                    alert(props.t('errorMsg'))
                }
            }
            fetchPendingOrders();
        }
    }

    const handleReject = async (order_id) => {
        /**
         * Do not delete order, just change status to false
         * sign that order is rejected
        */
        const response = await RejectParty(order_id);
        if (response.status === 200) {
            // if order is okey, fetch pending orders again
            fetchPendingOrders();
        }
    }

    const handleAcceptFlaw = async (flaw_id) => {
        /**
         * Delete flaw from database
        */
        const response = await removeFlaw(flaw_id);
        if (response.status === 204) {
            // if flaw is deleted, fetch pending flaws again
            fetchPendingFlaws();
        }
        setIsNotificationOpen(false)
    }

    const handleRejectFlaw = async (flaw_id) => {
        /**
         * Do not delete flaw
         * sign that flaw is rejected
        */
        const response = await RejectDeleteFlaw(flaw_id);
        if (response.status === 200) {
            // if flaw deleted, fetch pending flaws again
            fetchPendingFlaws();
        }
        setIsNotificationOpen(false)
    }

    const handleTab = (event) => {
        /*
            * Switch notification tab
        */
        const selectedTab = event.target;
        const targetTab = document.getElementById(selectedTab.getAttribute('data-bs-toggle'));

        // set active tab classname to tab
        const prevTab = document.getElementsByClassName('nav-item active-tab')[0];
        prevTab.className = 'nav-item';
        selectedTab.parentElement.className = 'nav-item active-tab';

        if (!targetTab.className.includes('tab-pane active show')) {
            // if selected tab do not have active class, 
            // first find active tab, and delete active class 
            // theen add active class to new tab
            const activeTab = document.getElementsByClassName('tab-pane active show')[0];
            activeTab.className = 'tab-pane';  // delete active class
            targetTab.className = 'tab-pane active show';  // add active class to new tab
        };
    };

    const notificationWebSocket = () => {
        const socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}/notification/`);
        // get web socket connection to server
        // listen to onopen event
        socket.onopen = () => {
            socket.send(JSON.stringify({
                action: 'list',
                request_id: new Date().getTime()
            }));
        };

        // listen to onmessage event
        socket.onmessage = (e) => {
            // update the state
            const data = JSON.parse(e.data);  // get websocket data from backend
            const action = data.action;  // get websocket action 
            const response = data.data;  // get websocket machine data
            if (action === 'list') {
                setNotification(response.results);
            }
            else if (action === 'create') {
                setNotification((prev) => [response.results, ...prev])
            }
            else {
                // remove deleted item from state
                setNotification(prev => prev.filter(item => item.id !== response.results.id))
            }
        };

        // Clean up the WebSocket connection
        return () => {
            socket.close();
        };
    };

    const fetchNotification = () => {
        notificationWebSocket();
    }

    const handleMarkAsRead = async (event, notification_id) => {
        event.preventDefault();
        event.stopPropagation();
        if (notification_id) {
            await markAsReadToFlaw(notification_id)
        }
        else {
            alert(props.t('errorMsg'))
        }
    };

    const handleRestoreFlaw = async (event, flaw_id, notification_id) => {
        /* 
            * Restore deleted flaw
        */
        const response = await restoreFlaw(flaw_id, notification_id);
        if (response.request.status === 200) {
            // setNotification([]);  // set notification as empty array
            // fetchNotification();  // fetch notification

            setIsNotificationOpen(false);  // close modal
            setAlert(true);
            setAlertMsg({
                type: "success",
                message: props.t('undoSuccessMsg')
            });

            setTimeout(() => {
                setAlert(null);
                setAlertMsg(null);
            }, 5000)
        }
    }


    useEffect(() => {
        fetchNotification();
    }, [])

    return (

        <React.Fragment>

            {
                alert && (
                    <UncontrolledAlert
                        color={alertMsg && alertMsg.type}
                        style={{ position: 'absolute', top: '50%', right: '1%', left: '80%', zIndex: '2000' }}
                    >
                        <span>{alertMsg && alertMsg.message}</span>
                    </UncontrolledAlert>
                )
            }

            <Modal isOpen={isNotificationOpen} toggle={toggleNotification} centered size='xl'>
                <ModalHeader toggle={toggleNotification}>
                    {
                        currentObject && currentObject.subject === "flaw" && (currentObject.message.action === "delete") ? (
                            <span>
                                <span className='me-1'>{props.t('notificationTitle')}</span>
                                <span className='me-1'>|</span>
                                <span className='text-muted'>{props.t('flawDelete')}</span>
                            </span>
                        ) : currentObject ? (
                            <span>
                                <span className='me-1'>{props.t('notificationTitle')}</span>
                                <span className='me-1'>|</span>
                                <span className='text-muted'>{props.t('flawDeleteRequest')}</span>
                            </span>
                        ) : (
                            <span>
                                <span className='me-1'>{props.t('notificationTitle')}</span>
                            </span>
                        )

                    }
                </ModalHeader>
                <ModalBody>
                    {
                        (currentObject && currentObject.message) ? (
                            <Row>
                                <Col sm={12} md={12} lg={6} xl={6}>
                                    <img
                                        // src={currentObject.message.object.image}
                                        src={`${process.env.REACT_APP_API_URL}${currentObject.message.object.image}`}
                                        className='w-100'
                                        height={350}
                                    />
                                </Col>
                                <Col sm={12} md={12} lg={6} xl={6} className="d-flex flex-column justify-content-between">
                                    <div className="d-flex flex-row justify-content-around" style={{height:"80%"}}>
                                        <div className='d-flex gap-2 mt-1 flex-column justify-content-around'>
                                            <span className='fw-bold'>
                                                {props.t('operator')}:
                                            </span>
                                            <span className='fw-bold'>
                                                {props.t('processes')}:
                                            </span>
                                            <span className='fw-bold'>
                                                {props.t('flawType')}:
                                            </span>
                                            <span className='fw-bold'>
                                                {props.t('partyNo')}:
                                            </span>
                                            <span className='fw-bold'>
                                                {props.t('rollNo')}:
                                            </span>
                                            <span className='fw-bold'>
                                                {props.t('date')}:
                                            </span>
                                        </div>
                                        <div className='d-flex gap-2 mt-1 flex-column justify-content-around'>
                                            <span>
                                                {currentObject.sender.username}
                                            </span>
                                            <span>
                                            {currentObject.subject === "flaw" && currentObject.message.action === "delete" ? props.t('flawDeleted') : ""}
                                            </span>
                                            <span >
                                            {currentObject.message.object.type}
                                            </span>
                                            <span >
                                                {currentObject.message.object.party_no}
                                            </span>
                                            <span >
                                                {currentObject.message.object.roll_count}
                                            </span>
                                            <span >
                                            {convertDate(currentObject.created_at)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-2 mt-1'>
                                        <Button
                                            size='sm'
                                            className='w-100 mt-2'
                                            color='outline-danger'
                                            onClick={(e) => handleRestoreFlaw(e, currentObject.message.object.id, currentObject.id)}>
                                            {props.t('undoProcess')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        ) : currentObject ? (
                            <Row>
                                <Col sm={12} md={12} lg={6} xl={6}>
                                    <img
                                        src={`${process.env.REACT_APP_API_URL}${currentObject.image}`}
                                        className='w-100'
                                        height={350}
                                    />
                                </Col>
                                <Col sm={12} md={12} lg={6} xl={6} className="d-flex flex-column justify-content-between">
                                    <div className="d-flex flex-row justify-content-around" style={{height:"80%"}}>
                                        <div className='d-flex gap-2 mt-1 flex-column justify-content-around'>
                                            <span className='fw-bold'>
                                                {props.t('operator')}:
                                            </span>
                                            <span className='fw-bold'>
                                                {props.t('processes')}:
                                            </span>
                                            <span className='fw-bold'>
                                                {props.t('flawType')}:
                                            </span>
                                            <span className='fw-bold'>
                                                {props.t('partyNo')}:
                                            </span>
                                            <span className='fw-bold'>
                                                {props.t('rollNo')}:
                                            </span>
                                            <span className='fw-bold'>
                                                {props.t('date')}:
                                            </span>
                                        </div>
                                        <div className='d-flex gap-2 mt-1 flex-column justify-content-around'>
                                            <span>
                                                {currentObject.user.username}
                                            </span>
                                            <span>
                                                {currentObject.deletion_request === true ? props.t('flawDeleteRequestMsg') : ""}
                                            </span>
                                            <span >
                                                {currentObject.flaw_type}
                                            </span>
                                            <span >
                                                {currentObject.party_no}
                                            </span>
                                            <span >
                                                {currentObject.roll_count}
                                            </span>
                                            <span >
                                                {convertDate(currentObject.created_at)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='d-flex gap-2 mt-1'>
                                        <Button
                                            size='sm'
                                            className='w-100 mt-2'
                                            color='outline-success'
                                            onClick={() => handleAcceptFlaw(currentObject.id)}>
                                            {props.t('approve')}
                                        </Button>
                                        <Button
                                            size='sm'
                                            className='w-100 mt-2'
                                            color='outline-danger'
                                            onClick={() => handleRejectFlaw(currentObject.id)}>
                                            {props.t('reject')}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                        ) : null
                    }
                </ModalBody>
            </Modal>

            <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ms-sm-3">
                {
                    (countOrder > 0 || countFlaw > 0) && (
                        <span style={{ position: 'absolute', right: '0.5rem', top: '0.2rem', zIndex: '5' }}>
                            <i className='bx bxs-circle fs-10' id='notification-alert'></i>
                        </span>
                    )
                }
                <DropdownToggle tag="button" type="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle ">
                    <span className="d-flex align-items-center">
                        <i className='bx bx-bell fs-22'></i>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end" end={true}
                    style={{ border: '1px solid #50c3e6', width: 'fit-content', minWidth: '520px', maxHeight: '300px', overflow: 'auto', paddingTop: 0 }}>
                    <ul className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
                        <li className="nav-item active-tab" role="presentation">
                            <Button className="nav-link w-100"
                                data-bs-toggle="order"
                                role="tab" aria-selected="false"
                                tabIndex="-1"
                                style={{ background: 'transparent', border: '0', color: 'black' }}
                                onClick={(e) => handleTab(e)}>
                                {props.t('order')}
                            </Button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <Button className="nav-link w-100"
                                data-bs-toggle="other"
                                role="tab"
                                aria-selected="false"
                                tabIndex="-1"
                                style={{ background: 'transparent', border: '0', color: 'black' }}
                                onClick={(e) => handleTab(e)}>
                                {props.t('other')}
                            </Button>
                        </li>
                    </ul>

                    <div className="tab-content text-muted">
                        <div className="tab-pane active show" id="order" role="tabpanel">
                            <div className="d-flex">

                                <div className="flex-grow-1">
                                    <DropdownItem header className='d-flex justify-content-start gap-3 ' style={{ alignItems: 'center' }}>
                                        <i className='bx bxs-help-circle fs-5 text-warning'></i>
                                        <p className='m-0 text-warning'>
                                            {props.t('notificationWarning')}:
                                        </p>
                                        <Button className='badge btn bg-soft-dark w-100'
                                            style={{ border: '1px solid black', padding: '0.1rem' }}
                                            onClick={() => {
                                                fetchPendingOrders();
                                                fetchPendingFlaws();
                                            }
                                            }
                                            id='refresh-notification'>
                                            <i className='bx bx-refresh fs-5 text-dark'></i>
                                        </Button>
                                    </DropdownItem>
                                    {
                                        pendingOrders && pendingOrders.map((item, index) => {
                                            const path = `/order/${item.id}`;
                                            return (
                                                // style={{minWidth: '400px', maxWidth: '500px', alignItems:'center'}}
                                                <DropdownItem style={{ alignItems: 'center' }}>
                                                    <span color="primary" className='w-100'>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <div className='d-flex'>
                                                                <a href={path} className='d-flex align-items-center'>
                                                                    {/* <i className='bx bx-server me-2'></i> */}
                                                                    <i className='bx bxs-shield text-soft-success fs-6 d-flex align-items-center justify-content-center' style={{ width: "64px", height: "64px" }}></i>
                                                                    <span className='fs-6 me-4 ms-2'>
                                                                        <OrderDeleteMessage
                                                                            item={item} />
                                                                    </span>
                                                                </a>
                                                            </div>
                                                            <div className='d-flex'>
                                                                <div className='badge bg-soft-success me-2'
                                                                    style={{ border: '1px solid #6ada7d', padding: '0.2rem 0.2rem' }}
                                                                    onClick={() => handleAccept(item.id)}>
                                                                    <i className='bx bx-check text-success fs-5'></i>
                                                                </div>
                                                                <div className='badge bg-soft-danger'
                                                                    style={{ border: '1px solid #fa896b', padding: '0.2rem 0.2rem' }}
                                                                    onClick={() => handleReject(item.id)}>
                                                                    <i className='bx bx-x text-danger fs-5'></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                </DropdownItem>
                                            )
                                        })
                                    }
                                    {
                                        pendingFlaws && pendingFlaws.map((item, index) => {
                                            return (
                                                <DropdownItem key={item.id} onClick={(e) => toggleNotification(e, item)}>
                                                    <span color="primary" className='w-100'>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='d-flex gap-3 align-items-center'>
                                                                <div>
                                                                    <img
                                                                        src={process.env.REACT_APP_API_URL + item.image}
                                                                        width='64'
                                                                        height='64'
                                                                        loading='lazy'
                                                                    />
                                                                </div>
                                                                <div className='d-flex flex-column gap-2'>
                                                                    <FlawDeleteMessage item={item} />
                                                                </div>
                                                                <div className='d-flex'>
                                                                    <div className='badge bg-soft-success me-2'
                                                                        style={{ border: '1px solid #6ada7d', padding: '0.2rem 0.2rem' }}
                                                                        onClick={() => handleAcceptFlaw(item.id)}>
                                                                        <i className='bx bx-check text-success fs-5'></i>
                                                                    </div>
                                                                    <div className='badge bg-soft-danger'
                                                                        style={{ border: '1px solid #fa896b', padding: '0.2rem 0.2rem' }}
                                                                        onClick={() => handleRejectFlaw(item.id)}>
                                                                        <i className='bx bx-x text-danger fs-5'></i>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </span>
                                                </DropdownItem>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane" id="other" role="tabpanel">
                            <div className="d-flex">
                                <div className="flex-grow-1">
                                    {
                                        notification && !isEmptyArray(notification) ? notification.map((item) => {
                                            return (
                                                // style={{minWidth: '400px', maxWidth: '500px', alignItems:'center'}}
                                                <DropdownItem key={item.id} onClick={(e) => toggleNotification(e, item)}>
                                                    <span color="primary" className='w-100'>
                                                        <div className='d-flex justify-content-between'>
                                                            <div className='d-flex gap-3 align-items-center'>
                                                                <div>
                                                                    <img
                                                                        src={process.env.REACT_APP_API_URL + item.message.object.image}
                                                                        width='64'
                                                                        height='64'
                                                                        loading='lazy'
                                                                    />
                                                                </div>
                                                                {
                                                                    item && item.message && (
                                                                        <div className='d-flex flex-column gap-2'>
                                                                            <OtherNotificationMessage item={{
                                                                                username: item.sender.username,
                                                                                party_no: item.message.object.party_no,
                                                                                roll_count: item.message.object.roll_count
                                                                            }} />
                                                                        </div>

                                                                    )
                                                                }
                                                                <Button
                                                                    className='ms-2'
                                                                    style={{ background: 'transparent', border: '0', padding: '0' }}
                                                                    onClick={(e) => handleMarkAsRead(e, item.id)}
                                                                >
                                                                    <i className='bx bx-x text-muted fs-3' id='btn-notification-read'></i>
                                                                </Button>
                                                            </div>

                                                        </div>
                                                    </span>
                                                </DropdownItem>
                                            )
                                        })
                                            :
                                            <DropdownItem className='text-center'>
                                                <span className='fw-bold'>{props.t('noNotification')}</span>
                                            </DropdownItem>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    )

}

export default Notification;
