import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/tr';
import { useTranslation } from 'react-i18next';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export default function DatePickerMaterialUI(props) {
  const { i18n } = useTranslation();

  // when language change update dayjs
  dayjs.locale(i18n.language);

  const dateFormat = i18n.language === 'tr' ? 'DD-MM-YYYY' : 'MM-DD-YYYY';

  const today = dayjs();

  const handleDateChange = (date) => {
    const validDate = date ? date : null;
    props.handleSelect(validDate, props.func);
  };

  return (
    <div>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}  // dayjs locale değerini alır
        key={i18n.language}            // her dil değişiminde yeniden render olur
      >
        <DatePicker
          label={props.label}
          value={props.date ? dayjs(props.date) : null}
          onChange={handleDateChange}
          format={dateFormat}
          disableFuture={props.type === "start"} // Future date for start is closed
          maxDate={
            props.type === "start"
              ? today.subtract(1, "day") // Max start date is yesterday
              : today.add(1, "day") // Max end date is one day from today
          }
          minDate={
            props.type === "end" && props.startDate
              ? dayjs(props.startDate) // Check start date with dayjs
              : null
          }
        />
      </LocalizationProvider>
    </div>
  );
}